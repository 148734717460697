import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'


const Terms = () => (
  <Layout>
    <SEO title='Terms & Conditions' />

    <div className='container mx-auto my-12 px-4 lg:px-8'>
      <h1 className='text-xl md:text-4xl text-center'>Terms & Conditions</h1>

      <div>
        <div>
          <div>
            <div>
              <div>1.About the Website</div>
              <div>1.1.Welcome to www.modeofficial.com (the 'Website'). The Website facilitates interactions between:</div>
              <div>(a)Buyers (the 'Receiver'); and</div>
              <div>(b)Sellers (the 'Provider'),</div>
              <div>making it easier for the Receiver and the Provider to locate, communicate, arrange payment and deliver the services in a fast and secure manner (the 'Services').</div>
              <div>1.2.The Website is operated by MODE FASHION AUSTRALIA PTY. LTD. (ACN 645 274 102) . Access to and use of the Website, or any of its associated products or Services, is provided by MODE FASHION AUSTRALIA. Please read these terms and conditions (the 'Terms') carefully. By using, browsing and/or reading the Website, this signifies that you have read, understood and agree to be bound by the Terms. If you do not agree with the Terms, you must cease usage of the Website, or any of its products or Services, immediately.</div>
              <div>1.3.MODE FASHION AUSTRALIA reserves the right to review and change any of the Terms by updating this page at its sole discretion. When MODE FASHION AUSTRALIA updates the Terms, it will use reasonable endeavours to provide you with notice of updates of the Terms. Any changes to the Terms take immediate effect from the date of their publication. Before you continue, we recommend you keep a copy of the Terms for your records.</div>
              <div>2.Acceptance of the Terms</div>
              <div>You accept the Terms by registering for the Services and/or making any payment as required under the Terms for use of the Services. You may also accept the Terms by clicking to accept or agree to the Terms where and if this option is made available to you by MODE FASHION AUSTRALIA in the user interface.</div>
              <div>3.The Services</div>
              <div>3.1.In order to access the Services, both the Receiver and the Provider are required to register for an account through the Website (the 'Account').</div>
              <div>3.2.As part of the registration process, or as part of your continued use of the Services, you may be required to provide personal information about yourself (such as identification or contact details), including:</div>
              <div>(a)Email address</div>
              <div>(b)Preferred username</div>
              <div>(c)Mailing address</div>
              <div>(d)Telephone number</div>
              <div>(e)Password</div>
              <div>(f)Name</div>
              <div>3.3.You warrant that any information you give to MODE FASHION AUSTRALIA in</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>
            <div>
              <div>the course of completing the registration process will always be accurate, correct and up to date.</div>
              <div>3.4.Once you have completed the registration process, you will be a registered member of the Website ('Member') and agree to be bound by the Terms.</div>
              <div>3.5.You may not use the Services and may not accept the Terms if:</div>
              <div>(a)you are not of legal age to form a binding contract with MODE FASHION AUSTRALIA; or</div>
              <div>(b)you are a person barred from receiving the Services under the laws of Australia or other countries including the country in which you are resident or from which you use the Services.</div>
              <div>4.Your obligations as a Member</div>
              <div>4.1.As a Member, you agree to comply with the following:</div>
              <div>(a)you will not share your profile with any other person;</div>
              <div>(b)you will use the Services only for purposes that are permitted by:</div>
              <div>(i)the Terms; and</div>
              <div>(ii)any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions;</div>
              <div>(c)you have sole responsibility for protecting the confidentiality of your password and/or email address. Use of your password by any other person may result in the immediate cancellation of the Services;</div>
              <div>(d)any use of your registration information by any other person, or third parties, is strictly prohibited. You agree to immediately notify MODE FASHION AUSTRALIA of any unauthorised use of your password or email address or any breach of security of which you have become aware;</div>
              <div>(e)you must not expressly or impliedly impersonate another Member or use the profile or password of another Member at any time;</div>
              <div>(f)any content that you broadcast, publish, upload, transmit, post or distribute on the Website ('Your Content') will always be accurate, correct and up to date and you will maintain reasonable records of Your Content.</div>
              <div>(g)you agree not to harass, impersonate, stalk, threaten another Member of the Website (where interaction with other Members is made available to you);</div>
              <div>(h)access and use of the Website is limited, non-transferable and allows for the sole use of the Website by you for the purposes of providing the Services;</div>
              <div>(i)you will not use the Services or the Website in connection with any commercial endeavours except those that are specifically endorsed or approved by the management of MODE FASHION AUSTRALIA;</div>
              <div>(j)you will not use the Services or Website for any illegal and/or unauthorised use which includes collecting email addresses of Members by electronic or other means for the purpose of sending unsolicited email or unauthorised framing of or linking to the Website;</div>
              <div>(k)you agree that commercial advertisements, affiliate links and other forms</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>
            <div>
              <div>of solicitation may be removed from Member profiles without notice and may result in termination of the Services. Appropriate legal action will be taken by MODE FASHION AUSTRALIA for any illegal or unauthorised use of the Website; and</div>
              <div>(l)you acknowledge and agree that any automated use of the Website or its Services is prohibited.</div>
              <div>5.Using the Website as the Receiver</div>
              <div>5.1.You can purchase items provided by other parties facilitated by MODE FASHION AUSTRALIA</div>
              <div>6.Using the Website as the Provider</div>
              <div>6.1.You can sell items facilitated by MODE FASHION AUSTRALIA</div>
              <div>7.Refund Policy</div>
              <div>7.1.Since MODE FASHION AUSTRALIA is only a facilitator in introducing the Receiver to the Provider and providing a system to make safe payment, MODE FASHION AUSTRALIA does not hold any liability to the Receiver directly and will not personally refund them any payments made in the use of Services.</div>
              <div>7.2.Notwithstanding the above clause, if a Receiver is unsatisfied with the services provided by the Provider or believes that they may be entitled to a refund, then MODE FASHION AUSTRALIA requires the Receiver to:</div>
              <div>(a)contact the Provider directly to request a refund; and</div>
              <div>(b)if contacting the Provider is not successful after fourteen (14) days, contact MODE FASHION AUSTRALIA through the 'Contact Us' section of the Website outlining why you believe you are entitled to a refund so we are able to determine if the Provider should be removed from the Services.</div>
              <div>7.3.If contacted by a Receiver who is requesting a refund pursuant to the above clause, the Provider agrees that it will immediately:</div>
              <div>(a)complete the MODE FASHION AUSTRALIA refund request form (the 'Request Form') provided on the Website; and</div>
              <div>(b)provide both the Request Form and the email from the Receiver requesting the refund to MODE FASHION AUSTRALIA.</div>
              <div>7.4.If the Provider agrees to a refund it is acknowledged that the Provider will instruct MODE FASHION AUSTRALIA to refund all or part of the payments made to the Receiver directly, less any fees and charges incurred by MODE FASHION AUSTRALIA in processing the refund.</div>
              <div>7.5.Both the Receiver and Provider agree that they will comply with the Refund Policy contained in this Clause of these Terms.</div>
              <div>8.Copyright and Intellectual Property</div>
            </div>
            <div>
              <div>8.1.The Website, the Services and all of the related products of MODE FASHION AUSTRALIA are subject to copyright. The material on the Website is protected by copyright under the laws of Australia and through international treaties.</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>
            <div>
              <div>Unless otherwise indicated, all rights (including copyright) in the Services and compilation of the Website (including but not limited to text, graphics, logos, button icons, video images, audio clips, Website, code, scripts, design elements and interactive features) or the Services are owned or controlled for these purposes, and are reserved by MODE FASHION AUSTRALIA or its contributors.</div>
              <div>8.2.All trademarks, service marks and trade names are owned, registered and/or licensed by MODE FASHION AUSTRALIA, who grants to you a worldwide, non-exclusive, royalty-free, revocable license whilst you are a Member to:</div>
              <div>(a)use the Website pursuant to the Terms;</div>
              <div>(b)copy and store the Website and the material contained in the Website in your device's cache memory; and</div>
              <div>(c)print pages from the Website for your own personal and non-commercial use.</div>
              <div>MODE FASHION AUSTRALIA does not grant you any other rights whatsoever in relation to the Website or the Services. All other rights are expressly reserved by MODE FASHION AUSTRALIA.</div>
              <div>8.3.MODE FASHION AUSTRALIA retains all rights, title and interest in and to the Website and all related Services. Nothing you do on or in relation to the Website will transfer any:</div>
              <div>(a)business name, trading name, domain name, trade mark, industrial design, patent, registered design or copyright, or</div>
              <div>(b)a right to use or exploit a business name, trading name, domain name, trade mark or industrial design, or</div>
              <div>(c)a thing, system or process that is the subject of a patent, registered design or copyright (or an adaptation or modification of such a thing, system or process),</div>
              <div>to you.</div>
              <div>8.4.You may not, without the prior written permission of MODE FASHION AUSTRALIA and the permission of any other relevant rights owners: broadcast, republish, up-load to a third party, transmit, post, distribute, show or play in public, adapt or change in any way the Services or third party Services for any purpose, unless otherwise provided by these Terms. This prohibition does not extend to materials on the Website, which are freely available for re-use or are in the public domain.</div>
              <div>8.5.Where you broadcast, publish, upload, transmit, post or distribute Your Content on the Website, then you grant to MODE FASHION AUSTRALIA a non-exclusive, transferrable, perpetual, royalty-free, irrevocable, worldwide licence to broadcast, republish, up-load to a third party, transmit, post, distribute, show or play in public, adapt or change Your Content.</div>
              <div>9.Privacy</div>
            </div>
            <div>
              <div>MODE FASHION AUSTRALIA takes your privacy seriously and any information provided through your use of the Website and/or Services are subject to MODE FASHION</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>
            <div>
              <div>AUSTRALIA's Privacy Policy, which is available on the Website.</div>
              <div>10.General Disclaimer</div>
              <div>10.1. Nothing in the Terms limits or excludes any guarantees, warranties, representations or conditions implied or imposed by law, including the Australian Consumer Law (or any liability under them) which by law may not be limited or excluded.</div>
              <div>10.2. Subject to this clause, and to the extent permitted by law:</div>
              <div>(a)all terms, guarantees, warranties, representations or conditions which are not expressly stated in the Terms are excluded; and</div>
              <div>(b)MODE FASHION AUSTRALIA will not be liable for any special, indirect or consequential loss or damage (unless such loss or damage is reasonably foreseeable resulting from our failure to meet an applicable Consumer Guarantee), loss of profit or opportunity, or damage to goodwill arising out of or in connection with the Services or these Terms (including as a result of not being able to use the Services or the late supply of the Services), whether at common law, under contract, tort (including negligence), in equity, pursuant to statute or otherwise.</div>
              <div>10.3. Use of the Website and the Services is at your own risk. Everything on the Website and the Services is provided to you "as is" and "as available" without warranty or condition of any kind. None of the affiliates, directors, officers, employees, agents, contributors and licensors of MODE FASHION AUSTRALIA make any express or implied representation or warranty about the Services or any products or Services (including the products or Services of MODE FASHION AUSTRALIA) referred to on the Website. This includes (but is not restricted to) loss or damage you might suffer as a result of any of the following:</div>
              <div>(a)failure of performance, error, omission, interruption, deletion, defect, failure to correct defects, delay in operation or transmission, computer virus or other harmful component, loss of data, communication line failure, unlawful third party conduct, or theft, destruction, alteration or unauthorised access to records;</div>
              <div>(b)the accuracy, suitability or currency of any information on the Website, the Services, or any of its Services related products (including third party material and advertisements on the Website);</div>
              <div>(c)costs incurred as a result of you using the Website, the Services or any of the products of MODE FASHION AUSTRALIA; and</div>
              <div>(d)the Services or operation in respect to links which are provided for your convenience.</div>
              <div>10.4. You acknowledge that MODE FASHION AUSTRALIA Website and the Services are only intended to facilitate the interactions between the Receiver and the Provider and does not offer any services other than the Services and MODE FASHION AUSTRALIA holds no liability to you as a result of any conduct of the Members or the misuse of Your Content by any party (including other Members).</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>
            <div>
              <div>11.Limitation of Liability</div>
              <div>11.1. MODE FASHION AUSTRALIA's total liability arising out of or in connection with the Services or these Terms, however arising, including under contract, tort (including negligence), in equity, under statute or otherwise, will not exceed the resupply of the Services to you.</div>
              <div>11.2. You expressly understand and agree that MODE FASHION AUSTRALIA, its affiliates, employees, agents, contributors and licensors shall not be liable to you for any direct, indirect, incidental, special consequential or exemplary damages which may be incurred by you, however caused and under any theory of liability. This shall include, but is not limited to, any loss of profit (whether incurred directly or indirectly), any loss of goodwill or business reputation and any other intangible loss.</div>
              <div>11.3. You acknowledge and agree that MODE FASHION AUSTRALIA holds no liability for any direct, indirect, incidental, special consequential or exemplary damages which may be incurred by you as a result of providing Your Content to the Website.</div>
              <div>12.Termination of Contract</div>
              <div>12.1. If you want to terminate the Terms, you may do so by providing MODE FASHION AUSTRALIA with 28 days' notice of your intention to terminate by sending notice of your intention to terminate to MODE FASHION AUSTRALIA via the 'Contact Us' link on our homepage.</div>
              <div>12.2. MODE FASHION AUSTRALIA may at any time, terminate the Terms with you if:</div>
              <div>(a)you have breached any provision of the Terms or intend to breach any provision;</div>
              <div>(b)MODE FASHION AUSTRALIA is required to do so by law;</div>
              <div>(c)MODE FASHION AUSTRALIA is transitioning to no longer providing the Services to Members in the country in which you are resident or from which you use the service; or</div>
              <div>(d)the provision of the Services to you by MODE FASHION AUSTRALIA is, in the opinion of MODE FASHION AUSTRALIA, no longer commercially viable.</div>
              <div>12.3. Subject to local applicable laws, MODE FASHION AUSTRALIA reserves the right to discontinue or cancel your membership at any time and may suspend or deny, in its sole discretion, your access to all or any portion of the Website or the Services without notice if you breach any provision of the Terms or any applicable law or if your conduct impacts MODE FASHION AUSTRALIA's name or reputation or violates the rights of those of another party.</div>
              <div>12.4. When the Terms come to an end, all of the legal rights, obligations and liabilities that you and MODE FASHION AUSTRALIA have benefited from, been subject to (or which have accrued over time whilst the Terms have been in force) or which are expressed to continue indefinitely, shall be unaffected by this cessation, and the provisions of this clause shall continue to apply to such</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>
            <div>
              <div>rights, obligations and liabilities indefinitely.</div>
              <div>13.Indemnity</div>
              <div>13.1. You agree to indemnify MODE FASHION AUSTRALIA, its affiliates, employees,</div>
              <div>agents, contributors, third party content providers and licensors from and against:</div>
              <div>(a)all actions, suits, claims, demands, liabilities, costs, expenses, loss and damage (including legal fees on a full indemnity basis) incurred, suffered or arising out of or in connection with Your Content;</div>
              <div>(b)any direct or indirect consequences of you accessing, using or transacting on the Website or attempts to do so; and/or</div>
              <div>(c)any breach of the Terms.</div>
              <div>14.Dispute Resolution</div>
              <div>14.1. Compulsory:</div>
              <div>If a dispute arises out of or relates to the Terms, either party may not commence any Tribunal or Court proceedings in relation to the dispute, unless the following clauses have been complied with (except where urgent interlocutory relief is sought).</div>
              <div>14.2. Notice:</div>
              <div>A party to the Terms claiming a dispute ('Dispute') has arisen under the Terms, must give written notice to the other party detailing the nature of the dispute, the desired outcome and the action required to settle the Dispute.</div>
              <div>14.3. Resolution:</div>
              <div>On receipt of that notice ('Notice') by that other party, the parties to the Terms ('Parties') must:</div>
              <div>(a)Within 28 days of the Notice endeavour in good faith to resolve the Dispute expeditiously by negotiation or such other means upon which they may mutually agree;</div>
              <div>(b)If for any reason whatsoever, 28 days after the date of the Notice, the Dispute has not been resolved, the Parties must either agree upon selection of a mediator or request that an appropriate mediator be appointed by the President of the Australian Mediation Association or his or her nominee;</div>
              <div>(c)The Parties are equally liable for the fees and reasonable expenses of a mediator and the cost of the venue of the mediation and without limiting the foregoing undertake to pay any amounts requested by the mediator as a pre-condition to the mediation commencing. The Parties must each pay their own costs associated with the mediation;</div>
              <div>(d)The mediation will be held in Melbourne, Australia.</div>
              <div>14.4. Confidential</div>
              <div>All communications concerning negotiations made by the Parties arising out of and in connection with this dispute resolution clause are confidential and to the extent possible, must be treated as "without prejudice" negotiations for the purpose of applicable laws of</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>
            <div>
              <div>evidence.</div>
              <div>14.5. Termination of Mediation:</div>
              <div>If 3 months have elapsed after the start of a mediation of the Dispute and the Dispute has not been resolved, either Party may ask the mediator to terminate the mediation and the mediator must do so.</div>
              <div>15.Venue and Jurisdiction</div>
              <div>The Services offered by MODE FASHION AUSTRALIA is intended to be viewed by residents of Australia. In the event of any dispute arising out of or in relation to the Website, you agree that the exclusive venue for resolving any dispute shall be in the courts of Victoria, Australia.</div>
              <div>16.Governing Law</div>
              <div>The Terms are governed by the laws of Victoria, Australia. Any dispute, controversy, proceeding or claim of whatever nature arising out of or in any way relating to the Terms and the rights created hereby shall be governed, interpreted and construed by, under and pursuant to the laws of Victoria, Australia, without reference to conflict of law principles, notwithstanding mandatory rules. The validity of this governing law clause is not contested. The Terms shall be binding to the benefit of the parties hereto and their successors and assigns.</div>
              <div>17.Independent Legal Advice</div>
              <div>Both parties confirm and declare that the provisions of the Terms are fair and reasonable and both parties having taken the opportunity to obtain independent legal advice and declare the Terms are not against public policy on the grounds of inequality or bargaining power or general grounds of restraint of trade.</div>
              <div>18.Severance</div>
              <div>If any part of these Terms is found to be void or unenforceable by a Court of competent jurisdiction, that part shall be severed and the rest of the Terms shall remain in force.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Terms

